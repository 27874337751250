import React from 'react'
import { Text } from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tr,
  Th,
  TableContainer,
} from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/react'
import { ResultTable } from './ResultTable'

interface IBackendResponse {
  backendResponse: any
  isLoading: boolean
  chartData: any[]
}

export const SearchResult = ({ backendResponse, isLoading, chartData }: IBackendResponse) => {
  const interval = 3600000 //1 hour\
  const endTime = new Date()
  endTime.setHours(0, 0, 0, 0)
  console.log('endtime: ' + endTime.toString())


  const ticksArray: number[] = []
  if (chartData.length === 0 && !isLoading) { //display empty table
    return (<div>
      <Text fontSize='lg'>Best Time Slots:</Text>
      <TableContainer>
        <Table variant='simple' size='sm' maxWidth='50%'>
          <Thead>
            <Tr>
              <Th style={{ textAlign: 'center' }}>Cron Time</Th>
              <Th style={{ textAlign: 'center' }} isNumeric>Max Conflicts</Th>
              <Th style={{ textAlign: 'center' }} isNumeric>Total Conflicts</Th>
            </Tr>
          </Thead>

        </Table>
      </TableContainer>
    </div>)
  } else if (isLoading) {
    return (<div style={{
      width: '100%', height: 400, display: 'flex', alignItems: 'center',
      justifyContent: 'center', flexDirection: 'column', rowGap: 10
    }}>
      <Spinner />
      <Text>Fetching Results...</Text>
    </div>)
  }
  if (chartData) {
    const tickEnd = chartData[chartData.length - 1].x
    const tickStart = chartData[0].x
    let i = 0
    while ((tickStart + i * interval) <= tickEnd) {
      ticksArray[i] = tickStart + i * interval
      i = i + 1
    }
  }

  return (
    <>
      <ResultTable backendResponse={backendResponse} ticksArray={ticksArray} chartData={chartData} />
    </>
  )
}
