const getDataForChart = (data: string) => {
    const dataArray = JSON.parse(data)
    const chartData: any[] = []
    for (let i = 0; i < dataArray.length; i++) {
        const dateObj = new Date(dataArray[i]['bin_timestamp'])
        const unixTime = dateObj.getTime()
        console.log(dataArray[i]['bin_timestamp'] + '  ' + unixTime)
        dateObj.setHours(dataArray[i]['hour'], dataArray[i]['min'])
        const coordinate = { x: unixTime, y: dataArray[i]['sum_count_'], x_new: 0 }
        chartData[i] = coordinate
    }
    chartData.sort((a, b) => (a.x > b.x) ? 1 : -1)
    return chartData
}

export default getDataForChart