import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import React, { useState } from 'react'
import { Text } from '@chakra-ui/react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react'
import moment from 'moment'
interface TableProps {
    backendResponse: any,
    ticksArray: any[],
    chartData: any[]
}
export const ResultTable = ({ backendResponse, ticksArray, chartData }: TableProps) => {
    const [hasNewBarData, setHasNewBarData] = useState(false)
    const [dataArrayToChart, setDataArrayToChart] = useState<any[]>(chartData)
    const handleTableRowEnter = (event, cron: string) => {
        const newBarData = JSON.parse(JSON.stringify(chartData)) as typeof chartData
        const endDateTime = new Date()
        endDateTime.setHours(0, 0, 0, 0)
        const startDateTime = new Date(endDateTime.getTime() - 86400000)
        startDateTime.setHours(0, 0, 0, 0)
        const timeArray: number[] = []
        const options = {
            currentDate: startDateTime,
            endDate: endDateTime
        }
        console.log(startDateTime.toString() + '  ' + endDateTime.toString())
        console.log('chart data:')
        console.log(dataArrayToChart)
        const parser = require('cron-parser')
        const interval = parser.parseExpression(cron, options)
        while (true) {
            try {
                const obj = interval.next()
                timeArray.push(obj.getTime())
            } catch (e) {
                break
            }
        }

        //add one more conflict to corresponding y coordinate
        for (const timestamp of timeArray) {
            console.log((new Date(timestamp)).toLocaleTimeString())
            let last_coord = newBarData[0]
            for (const coord of newBarData) {
                if (timestamp > coord.x) {
                    last_coord = coord
                    continue
                } else {
                    if (Math.abs(last_coord.x - timestamp) > Math.abs(coord.x - timestamp)) {
                        coord.x_new = coord.x_new + 1
                    } else {
                        last_coord.x_new = last_coord.x_new + 1
                    }
                    break
                }
            }
        }

        setHasNewBarData(true)
        setDataArrayToChart(newBarData)
    }

    const handleTableRowLeave = (event) => {
        console.log('Mouse left!!!')
        setHasNewBarData(false)
        setDataArrayToChart(chartData)
        console.log(chartData)
    }

    return (
        <>
            <Text fontSize='lg'>Best Time Slots:</Text>
            <TableContainer>
                <Table variant='simple' size='sm' maxWidth='50%'>
                    <Thead>
                        <Tr>
                            <Th style={{ textAlign: 'center' }}>Cron Time</Th>
                            <Th style={{ textAlign: 'center' }} isNumeric>Max Conflicts Per Run</Th>
                            <Th style={{ textAlign: 'center' }} isNumeric>Total Conflicts</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {backendResponse &&
                            backendResponse.map((row: string[], i: number) => {
                                return (
                                    <Tr key={i} onMouseEnter={(e) => handleTableRowEnter(e, row[0])} onMouseLeave={(e) => handleTableRowLeave(e)} className='tableRow'>
                                        <Td style={{ textAlign: 'center' }}>{row[0]}</Td>
                                        <Td style={{ textAlign: 'center' }}>{row[1]}</Td>
                                        <Td style={{ textAlign: 'center' }}>{row[2]}</Td>
                                    </Tr>)
                            })
                        }
                    </Tbody>

                </Table>
            </TableContainer>
            <Text fontSize='lg'>Scheduled Analytics:</Text>

            <BarChart width={1400} height={400} data={dataArrayToChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey='x' scale='time' type='number' domain={['auto', 'auto']} minTickGap={10} tickCount={20} ticks={ticksArray} tickFormatter={(unixTime) => moment(unixTime).format('MM-DD HH:mm')} interval='preserveStartEnd'
                />
                <YAxis dataKey='y' />
                <Tooltip labelFormatter={(label) => moment(label).format('MM-DDTHH:mm:ss')}
                />
                <Legend />
                <Bar dataKey='y' fill="#8884d8" stackId='a' name='Number of Conflicts' />
                {
                    (hasNewBarData) ?
                        <Bar dataKey='x_new' stackId='a' fill='#c95353' name='Resulting Schedule' /> : null
                }
            </BarChart>
        </>
    )
}