import React, { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { tokenRequest } from '../../utils/authConfig'
import { CallApi } from '../../utils/api'
import { Button, useToast } from '@chakra-ui/react'


interface Props {
    instanceGuid: string;
}

interface EmailData {
    managerName: string;
    managerEmail: string;
    actorName: string;
    alertTitle: string;
}

interface EmailContent {
    subject: string;
    body: string;
    email: string;
}


export const SendToManager = ({ instanceGuid }: Props) => {
    const { instance, accounts } = useMsal()
    const toast = useToast()

    const [emailData, setEmailData] = useState<EmailData | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (accounts.length === 0) {
            toast({
                title: "No accounts found.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setLoading(false);
            return;
        }

        instance.acquireTokenSilent({
            ...tokenRequest,
            account: accounts[0]
        }).then((response) => {
            CallApi('/alerts/getEmailData/' + instanceGuid, response.accessToken, 'Get')
                .then((response) => {
                    setEmailData(response as EmailData);
                    setLoading(false);
                })
                .catch((error) => {
                    toast({
                        title: "Failed to fetch email data.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    setLoading(false);
                });
        }).catch((error) => {
            toast({
                title: "Failed to acquire token.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setLoading(false);
        });
    }, []);

    const generateEmailContent = (): EmailContent => {
        if (!emailData) {
            return {
                subject: '',
                body: '',
                email: ''
            }
        }

        const { managerName, managerEmail, actorName, alertTitle } = emailData;
        let subject = '';
        let body = '';
        let email = '';

        if (alertTitle.toLowerCase().includes('anomalous amount of cloning activity') || alertTitle.toLowerCase().includes('large anomalous clone')) {
            subject = `[Action Required] Direct Report ${actorName} Conducted Anomalous Amount of Cloning`;
            body = `Hello ${managerName},

I am reaching out on behalf of the 1ES Security Monitoring Team.  We had a security alert coming into our system claiming that ${actorName}, who is a direct report to you,  has conducted a large anomalous clone volume in ADO <IcM Incident Link>. This may indicate that their identity has been compromised. Are you able to validate that this behavior was expected and was aligned with their regular job? Details below:`;
    
            email = `${managerEmail}`
        } else if (alertTitle.toLowerCase().includes('out-of-bound aad application')) {
            subject = `[Action Required] Service connection id <add service connection id here> you own has been detected with 3rd party Azure DevOps organization.`;
            body = `Hello <Alias>,
We are reaching out to you on behalf of the One Engineering System Blue Team. One or more of the service connections you own has been detected within a 3rd party Azure DevOps organization.

<IcM Incident Link>
Why is this bad?

This detects service connections from 3rd party Azure DevOps orgs connection to a 1st party Entra tenant. These are effectively leaked 1st party secrets - they provide a pathway from unmanaged/unmonitored Azure DevOps organizations into 1st party resources. Swift action is required to remediate as it violates Microsoft corporate security policy.

Action Required

Please rotate all the secrets on the app and remove it from that ADO project. Detailed instructions: Unmanaged exposure of an AzDO service connection (Link:https://eng.ms/docs/cloud-ai-platform/devdiv/one-engineering-system-1es/1es-docs/1es-security-configuration/azdo-config-remediation/unmanaged-svc-conn) .

Next Steps

Please treat with Sev 2.5 priority (fix during business hours as soon as possible) and reply us once done. We will review usage and resolve the ICM ticket.

Thank you,

1esblue@microsoft.com`;
            email = 'alias@microsoft.com';
        } else {
            subject = `[Action Required] ${alertTitle}`;
            body = `Hello ${managerName},
${actorName} has the following alert: ${alertTitle} ,
Please take necessary actions.

Best,
1ES Blue Team`;
            email = `${managerEmail}`;
        }
        return { subject, body, email };
    };

    function generateMailToLink(): string {
        if (!emailData)
            return '#'

        const { subject, body, email } = generateEmailContent();
        const encodedSubject = encodeURIComponent(subject);
        const encodedBody = encodeURIComponent(body);


        return `mailto:${email}?subject=${encodedSubject}&body=${encodedBody}`
    }

    if (loading) {
        return null;
    }

    return (
        <a href={generateMailToLink()} style={{textDecoration: 'none', width:"100%"}}>
            <Button
                w={'100%'}
                flex={1}
                fontSize={'sm'}
                rounded={'full'}
                color={'black'}
                boxShadow={'0px 1px 25px -5px rgb(0 0 225 / 48%), 0 10px 10px -5px rgb(0 0 225 / 43%)'}
                _hover={{bg: 'blue.500'}}
                _focus={{
                    bg: 'blur.500',
                }}
            >
            Send Email to Manager
            </Button>
        </a>
    );
};


