import React, { useState } from 'react'
import { Button, Input } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/react'
import { Select } from '@chakra-ui/react'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { CallApi } from '../../utils/api'
import { useMsal } from '@azure/msal-react'
import { tokenRequest } from '../../utils/authConfig'
import getDataForChart from './getDataForChart'

interface ISearchBarProps {
	setBackendResponse: (data: any) => void
	setIsLoading: (data: boolean) => void
	setChartData: (data: any[]) => void
}
export const SearchBar = ({ setBackendResponse, setIsLoading, setChartData }: ISearchBarProps) => {
	const { instance, accounts } = useMsal()
	const [interval, setInterval] = useState('')
	const handleIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => setInterval(event.target.value)
	const [unitOfFrequency, setUnit] = useState('3600')
	const handleUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => setUnit(event.target.value)
	const bodyFormData = new FormData()
	bodyFormData.set('interval', interval)
	bodyFormData.set('unitOfFrequency', unitOfFrequency)

	const handleSearch = () => {
		if (interval !== '') {
			instance.acquireTokenSilent({
				...tokenRequest,
				account: accounts[0]
			}).then((response) => {
				CallApi('/schedules/searchSchedule', response.accessToken, 'Post', bodyFormData).then((response: any) => {
					setIsLoading(false)
					setBackendResponse(response.resultToTable)
					setChartData(getDataForChart(response.jsonResult))
				})
			})
		}
	}

	const handleButtonClick = () => {
		if (interval != '') {
			setIsLoading(true)
			handleSearch()
		}
	}
	return (
		<>
			<Text fontSize='lg'>Frequency of Detection:</Text>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: 400 }}>
				<Text>Every</Text>
				<Input height={9} width={16} variant='outline' placeholder='e.g. 1' size='sm' value={interval} type='number' isRequired onChange={handleIntervalChange} />
				<div style={{ width: 119 }}>
					<Select onChange={handleUnitChange} value={unitOfFrequency} height={9} >
						<option value='3600'>{'Hour(s)'}</option>
						<option value='60'>{'Minute(s)'}</option>
						<option value='86400'>{'Day(s)'}</option>
					</Select>
				</div>
				<Tooltip label="Enter the desired integer frequency for your detection to run, for example, every 3 hours.">
					<QuestionOutlineIcon w={5} h={9} />

				</Tooltip>
				<Button size='md' colorScheme='blue' onClick={handleButtonClick}>Search</Button>

			</div>
		</>
	)
}