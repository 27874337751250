import React, { useEffect, useState } from 'react'
import { Grid, GridItem, Text, Checkbox, Select, Flex, filter } from '@chakra-ui/react'
import { WarningIcon } from '@chakra-ui/icons'
import { MatrixGrid } from '../components/coverageMatrix/MatrixGrid'
import { useMsal } from '@azure/msal-react'
import { tokenRequest } from '../utils/authConfig'
import { CallApi } from '../utils/api'

export const CoverageMatrix = () => {

    const [ targetSystem, setTargetSystem ] = useState<string>("Azure DevOps")
    const [ socTeams, setSocTeams ] = useState<string[]>([])
    const [ targetTeam, setTargetTeam ] = useState<string>("all")

    const { instance, accounts } = useMsal()

    useEffect(() => {
            // Silently acquires an access token which is then attached to a request for the dotnet backend api
            instance.acquireTokenSilent({
                ...tokenRequest,
                account: accounts[0]
            }).then((response) => {
                CallApi('/coveragematrix/getSocTeams/', response.accessToken, 'Get')
                    .then((response: any) => {
                        setSocTeams((JSON.parse(response)).map((team: any) => team.Partner).sort())
                        console.log(socTeams)
                    })
            })
        }, []) // Pass empty array to avoid re-rendering on every change (only runs this code once)
    
    return (
        <>
            <Flex alignItems={'center'} mb={'8px'}>
				<span>Coverage matrix in system </span>
				<Select w={"200px"} mx={"8px"} onChange={(e) => setTargetSystem(e?.target?.value)}>
					<option value='Azure DevOps'>Azure DevOps</option>
					<option value='ghecemu'>GHEC EMU</option>
					<option value='cloudbuild'>CloudBuild</option>
				</Select>
                <span>for team </span>
                <Select w={"400px"} ml={"8px"} onChange={(e) => setTargetTeam(e?.target?.value)}>
                    <option value='all'></option>
					{
                        socTeams.length > 0 ? socTeams.map((team, i) => <option value={team} key={i}>{team}</option>) : <option value=''>Loading...</option>
                    }
				</Select>
            </Flex>

            <span>Note: Data is based on feedback provided by team, an alert will count as onboarded if team has provided feedback for at least 1 occurrence of the alert </span>

            <MatrixGrid system={targetSystem} matrixType={"coverage"} targetTeam={targetTeam}/>
        </>
    )
}