import axios from 'axios'

const apiUrl: any = process.env.REACT_APP_API_URL || "https://localhost:7042/api" // env variable in static web app or localhost url

export function CallApi(urlPath: string, bearerToken: string, httpMethod: string, bodyFormData?: any) {
	return axios(apiUrl + urlPath,
		{
			method: httpMethod,
			headers: {
				'Authorization': `Bearer ${bearerToken}`,
				'Content-Type': 'multipart/form-data',
			},
			data: bodyFormData,
		}
	)
		.then((response) => {
			console.log(response.data)
			return response.data
		})
		.catch((error) => {
			console.log(error)
		}
		)
}