import React, { useState, useEffect } from 'react'

import { useMsal } from '@azure/msal-react'
import { tokenRequest, loginRequest } from '../../utils/authConfig'
import { CallApi } from '../../utils/api'
import { callMsGraph, getUserPhoto } from '../../utils/graph'


import {
    Heading,
    Avatar,
    Box,
    Center,
    Text,
    Stack,
    Button,
    useColorModeValue,
    Spinner,
    Link
  } from '@chakra-ui/react';

interface Props {
  instanceGuid: string;
}

export const ActorCard = ({instanceGuid} : Props) => {

  const { instance, accounts } = useMsal()

  const [actorData, setActorData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [graphData, setGraphData] = useState<any>(null);

  // Get actor info from Kusto
  useEffect(() => {
		// Silently acquires an access token which is then attached to a request for the dotnet backend api
		instance.acquireTokenSilent({
			...tokenRequest,
			account: accounts[0]
		}).then((response) => {
			CallApi('/alerts/getActorInfo/' + instanceGuid, response.accessToken, 'Get')
				.then((response: any) => {
          console.log(response)
					setActorData(JSON.parse(response)[0])
					setLoading(false)

          // TODO (Optional): Get admin consent in app registration to get user photo via Microsoft Graph
          // Get user photo from Microsoft Graph
          /*  instance.acquireTokenSilent({
              ...loginRequest,
              account: accounts[0]
            }).then((response) => {
              // Get profile from other user
              getUserPhoto(response.accessToken, actorData.EmailAddress).then(response => setGraphData(response))
            })
            */


				})
		})
	}, []) // Pass empty array to avoid re-rendering on every change (only runs this code once)

  

  if(loading || !actorData) {
    return (
      <Center>
          <Box
            maxW={'320px'}
            w={'full'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}>
            <Center mb={"20px"}>
                <Spinner m={"30px"} size='xl' />
            </Center>

            <Stack mt={4} direction={'row'} spacing={4}>
              <Button
                flex={1}
                fontSize={'sm'}
                rounded={'full'}
                _focus={{
                  bg: 'gray.200',
                }}>
                Who+
              </Button>
              <Button
                flex={1}
                fontSize={'sm'}
                rounded={'full'}
                bg={'red.400'}
                color={'white'}
                boxShadow={
                  '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                }
                _hover={{
                  bg: 'red.500',
                }}
                _focus={{
                  bg: 'red.500',
                }}>
                Report
              </Button>
            </Stack>
          </Box>
        </Center>
    )
  }
  else {

    return (
        <Center>
          <Box
            maxW={'320px'}
            w={'full'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            pb={12}
            textAlign={'center'}>
            <Heading fontSize={'xl'} fontFamily={'body'}>
            {actorData.DisplayName}
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={0}>
            {actorData.JobTitle}
            </Text>
            <Text fontWeight={350} color={'gray.500'} mb={4}>
            {actorData.Department}
            </Text>
            <Text
              textAlign={'left'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}>
              {actorData.CityName} ({actorData.BuildingCountry})
            </Text>
            <Text
              textAlign={'left'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}>
              {actorData.EmailAddress}
            </Text>
    



            <Center py={6}>
                <Stack
                    borderWidth="1px"
                    borderRadius="lg"
                    w={{ sm: '100%', md: '540px' }}
                    height={{ sm: '476px', md: '11rem' }}
                    direction={{ base: 'row', md: 'column' }}
                    bg={useColorModeValue('white', 'gray.900')}
                    boxShadow={'2xl'}
                    padding={4}>
                      <Heading fontSize={'xl'} fontFamily={'body'} textAlign={'center'}>
                        Manager
                    </Heading>                    
                      <Stack
                      flex={1}
                      flexDirection="column"
                      justifyContent="start"
                      alignItems="start"
                      p={1}
                      pt={2}>
                        <Text fontWeight={600} color={'black.500'} size="sm" fontSize='md'>
                          {actorData.ManagerName}
                        </Text>
                        <Text fontWeight={600} color={'gray.500'} size="sm" fontSize='sm'>
                          {actorData.ManagerTitle}
                        </Text>
                        <Text fontWeight={350} color={'gray.500'} size="sm" fontSize='sm'>
                          {actorData.ManagerDepartment}
                        </Text>
                      </Stack>
                    
                    
                </Stack>
            </Center>







            <Stack mt={4} direction={'row'} spacing={4}>
              <Link style={{ textDecoration: 'none' }} w={'100%'} href={"https://whoplus.microsoft.com/Org/" + actorData.Alias} target="_blank">
                <Button
                  w={'100%'}
                  flex={1}
                  fontSize={'sm'}
                  rounded={'full'}
                  _focus={{
                    bg: 'gray.200'
                  }}>
                  Who+
                </Button>
              </Link>
              <Link style={{ textDecoration: 'none' }} w={'100%'} href="http://aka.ms/reportitnow" target="_blank">
                <Button
                  w={'100%'}
                  flex={1}
                  fontSize={'sm'}
                  rounded={'full'}
                  bg={'red.400'}
                  color={'white'}
                  boxShadow={
                    '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                  }
                  _hover={{
                    bg: 'red.500',
                  }}
                  _focus={{
                    bg: 'red.500',
                  }}>
                  Report
                </Button>
              </Link>
              
            </Stack>
          </Box>
        </Center>
      );
  }
};