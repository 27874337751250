import React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Text,
    Spinner,
    Flex,
    Center,
    Stack
  } from '@chakra-ui/react'

interface Props {
    rawData: any;
    loading: boolean;
}

export const AlertRawDataTable = ({rawData, loading} : Props) => {

    function LoadingComponent() {
        return (
            <Flex width={'100%'} height={'100%'} alignContent={'center'} justifyContent={'center'}>
                <Center>
                    <Stack my={"60px"}>
                        <Center mb={"20px"}>
                            <Spinner size='xl' />
                        </Center>
                        <Text>Loading Raw Data...</Text>
                    </Stack>
                </Center>
            </Flex>
        )
    }

    if(!loading) {
        // Remove empty keys from mapped data
        let mappedDataKeys = Object.keys(rawData)
        mappedDataKeys.forEach((key, i) => {
            if(rawData[key] == null || rawData[key].length === 0) {
                delete rawData[key]
            }
        })

        // sort alphabetically
        let mappedDataKeysSorted = Object.keys(rawData).sort()
        let mappedDataSorted:any = {}
        mappedDataKeysSorted.forEach((key, i) => {
            mappedDataSorted[key] = rawData[key]
        })
        rawData = mappedDataSorted        
    }
    
    // map data from json to table
    let dataRows:any = null
    if (!loading) {
        // Remove keys with same values as other keys
        let mappedDataKeys = Object.keys(rawData)
        mappedDataKeys.forEach((key, i) => {
            mappedDataKeys.forEach((key2, i2) => {
                if(key !== key2 && rawData[key] === rawData[key2]) {
                    delete rawData[key]
                }
            })
        })

        dataRows = Object.keys(rawData).map((key, i) => {

            // Order arrays within keys
            if(typeof (rawData as any)[key] == 'object')
            {
                // Order array alphabetically
                rawData[key] = (rawData[key]).sort()
            }

            return (
                <Tr key={i}>
                    <Td>{key}</Td>
                    <Td>{typeof (rawData as any)[key] == 'object' ? (rawData as any)[key].map((arrayItem:any, itemIndex:number) => {
                        return (
                            <Text key={itemIndex}>{JSON.stringify(arrayItem)}</Text>
                        )
                    }) : (rawData as any)[key]}</Td>
                </Tr>
            )
        })
    }


    if(!loading) {
        return (
            <>
                <TableContainer>
                    <Table variant='simple' size='sm'>
                        <Tbody>
                            {dataRows}
                        </Tbody>
                    </Table>
                </TableContainer>            
            </>
        );
    }
    else {
        return (
            <LoadingComponent />
        )
    }

};