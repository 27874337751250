import React, { useEffect, useState } from 'react'

import { GridItem, Text, Center, Alert, AlertTitle, AlertIcon, Modal, Button, Box, Link,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    useDisclosure, } from '@chakra-ui/react'

interface IAlertsList {
    prodAlerts: number;
    devAlerts: number;
    signals: number;
}

interface Props {
    alerts: any;
    type: "coverage" | "attack";

}

export const MatrixGridItem = ({alerts, type} : Props) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [ onboardedAlerts, setOnboardedAlerts ] = useState<string[]>([])
    const [ notOnboardedAlerts, setNotOnboardedAlerts ] = useState<string[]>([])

    // run once on component render
    useEffect(() => {
        if(type == "coverage")
        {
            if(alerts.length > 0){
                console.log(alerts)
                // Filter onboarded and not onboarded analytics into different arrays
                setOnboardedAlerts(alerts.filter((alert: any) => alert.Onboarded == 1))
                setNotOnboardedAlerts(alerts.filter((alert: any) => alert.Onboarded == 0))
            }
        }
        
    }, [alerts])


    const openModule = () => {
        if (alerts.length > 0) {
            onOpen()
        }
    }

    const getEnvironmentSwitch = (source: string) => {
        switch (source) {
            case "1ESKalypsoAlerts":
                return "Production"
            case "1ESKalypsoDiagnostic":
                return "Staging"
            case "1ESKalypsoDuplicates":
                return "Duplicate"
            default:
                return "Other"
        }
    }

	return (
        <>
            <GridItem w='25vh' h='53' onClick={openModule} _hover={alerts.length > 0 ? {cursor: "pointer"} : {}}>
                <Center h={'100%'}>
                    {
                        type == "coverage" ?
                        <>
                            {
                                alerts.length > 0 ?
                                        <Alert status={onboardedAlerts.length == alerts.length ? "success" : onboardedAlerts.length > 0 ? "warning" : "error"}>
                                            <AlertIcon />
                                            <AlertTitle>
                                                {
                                                    notOnboardedAlerts.length > 0 ?
                                                    <>{onboardedAlerts.length}/</>
                                                    :
                                                    <> </>
                                                } 
                                                {alerts.length} analytic(s)
                                            </AlertTitle>
                                        </Alert>
                                : 
                                <Alert colorScheme={"gray"}>
                                    <AlertIcon />
                                    <AlertTitle>No analytics</AlertTitle>
                                </Alert>
                            }
                        </>
                        :
                        <>
                            {
                                alerts.length > 0 && type == "attack" ?
                                (<Alert status='warning'>
                                    <AlertIcon />
                                    <AlertTitle>{alerts.length} alerts triggered</AlertTitle>
                                </Alert>)
                                : 
                                <Alert colorScheme={"gray"}>
                                    <AlertIcon />
                                    <AlertTitle>No info</AlertTitle>
                                </Alert>
                            }
                        </>
                    }
                </Center>
            </GridItem>

            <Modal size={type == "coverage" ? "4xl" : "6xl"} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Detections</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer>
                            <Table size='sm'>
                                <Thead>
                                    <Tr>
                                        {
                                            type == "coverage" ?
                                            <>
                                                <Th>Name</Th>
                                                <Th>Environment</Th>
                                                <Th>Status</Th>
                                                <Th>Impact</Th>
                                                <Th>Confidence</Th>
                                            </>
                                            :
                                            <>
                                                <Th>Timestamp</Th>
                                                <Th>Alert</Th>
                                                <Th>Environment</Th>
                                            </>
                                        }
                                        
                                    </Tr>
                                    </Thead>
                                    <Tbody>

                                    
                            {
                                type == "coverage" ?
                                <>
                                    {
                                        alerts.map((alert: any, index: number) => {
                                            return (
                                                <>
                                                    
                                                    <Tr>
                                                        
                                                        <Td>
                                                            <Link href={"https://dev.azure.com/1ESSecurity/_git/Analytics?path=/Analytics/Analytics/Monitors" + alert.FilePath.replace("\\", "/")}>
                                                                {alert.AnalyticName}
                                                            </Link>
                                                        </Td>
                                                        <Td>{alert.Environment}</Td>
                                                        <Td>{alert.Onboarded ? "Onboarded" : "Not onboarded"}</Td>
                                                        <Td>{alert.Impact}</Td>
                                                        <Td>{alert.Confidence}</Td>
                                                    </Tr>
                                                </>
                                            )
                                        })
                                    }
                                </>
                                :
                                <>
                                    {
                                        alerts.map((alert: any) => {
                                            return (
                                                <>
                                                    
                                                    <Tr>
                                                        <Td>{alert.Timestamp.substring(0, 19)}</Td>
                                                        <Td>
                                                            <Link href={"/alerts/" + alert.InstanceGuid}>
                                                                {alert.Title}
                                                            </Link>
                                                        </Td>
                                                        <Td>
                                                            {
                                                                getEnvironmentSwitch(alert.source_)
                                                            }
                                                        </Td>
                                                    </Tr>
                                                </>
                                            )
                                        })
                                    }
                                </>
                                
                            }
                                </Tbody>
                            </Table>
                        </TableContainer>
                        
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
	)
}