import React, { useState } from 'react'
import { SearchBar } from '../components/schedules/SearchBar'
import { SearchResult } from '../components/schedules/SearchResult'

export const KalypsoSchedule = () => {
	const [backendResponse, setBackendResponse] = useState<any>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [chartData, setChartData] = useState<any[]>([])

	return (
		<>
			<SearchBar setBackendResponse={setBackendResponse} setIsLoading={setIsLoading} setChartData={setChartData} />
			<SearchResult backendResponse={backendResponse} isLoading={isLoading} chartData={chartData} />
		</>
	)
}
