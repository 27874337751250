import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/index.css'
import App from './App'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './utils/authConfig'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// Initialize Fluent UI icons
// import { initializeIcons } from '@fluentui/react/lib/Icons';

// initializeIcons(/* optional base url */);

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig)

const container = document.getElementById('root')
const root = createRoot(container!)
console.log(process.env)
console.log("Api Url: " + process.env.REACT_APP_API_URL)
console.log("Redirect url: " + process.env.REACT_APP_REDIRECT_URL)
root.render(
	<MsalProvider instance={msalInstance}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</MsalProvider>
)
