import React, { useState, useEffect } from 'react'

// Authentication imports
import { useMsal } from '@azure/msal-react'

import {
    Box,
    Center,
    Text,
    Stack,
    Button,
    useColorModeValue,
  } from '@chakra-ui/react';

interface Props {
    feedback: any;
    loading: boolean;
}

export const Feedback = ({feedback, loading} : Props) => {

    const { accounts } = useMsal()
    const [feedbackData, setFeedbackData] = useState<any>(feedback.Value)
    const [feedbackSubmitted, setFeedbackSubmitted] = useState<any>(null)

    console.log(feedback)
    console.log(feedbackData)

    function submitFeedback(value: string) {
        fetch('https://aka.ms/SecMonFeedbackDev?InstanceId=' + feedback.InstanceId + '&SourceName=FarcasterWebApp&SourceUser=' + accounts[0].username + '&Value=' + value)
            .then((response: any) => {
                setFeedbackData(value)
                setFeedbackSubmitted(true)
            })
    }

    // Set feedbackdata if loading is false
    useEffect(() => {
        if (!loading) {
            setFeedbackData(feedback.Value)
        }
    }
    , [loading])

    const FeedbackMetadata = () => {
        if(feedback.FeedbackTime) {
            return (
                <>
                    <Text pt={"20px"}>Submitted by:</Text>
                    <Text>{feedback.Author}</Text>
                    <Text>{feedback.FeedbackTime.replace("T", " ").substring(0, 16) + " UTC"}</Text>
                </>
            )
        } else { return null }
    }

    return (
        <Center>
            <Box
                maxW={'320px'}
                w={'320px'}
                bg={useColorModeValue('white', 'gray.900')}
                boxShadow={'2xl'}
                rounded={'lg'}
                p={6}
                textAlign={'center'}>

                <Stack>
                    <Text>{feedbackSubmitted ? "Feedback submitted!" : "Alert Classification"}</Text>
                
                    <Button isLoading={loading} colorScheme={feedbackData == "TP" ? "green" : "gray"} onClick={() => submitFeedback("TP")}>True Positive</Button>
                    <Button isLoading={loading} colorScheme={feedbackData == "BP" ? "green" : "gray"} onClick={() => submitFeedback("BP")}>Benign True Positive</Button>
                    <Button isLoading={loading} colorScheme={feedbackData == "FP" ? "green" : "gray"} onClick={() => submitFeedback("FP")}>False Positive</Button>

                    {FeedbackMetadata()}
                    
                </Stack>

            </Box>
        </Center>
      );
};