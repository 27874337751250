import React, { useEffect, useRef, useState } from 'react'
import { Grid, GridItem, Text, Checkbox, Select, Flex, Input, Box } from '@chakra-ui/react'
import { WarningIcon } from '@chakra-ui/icons'
import { MatrixGrid } from '../components/coverageMatrix/MatrixGrid'
import { OtherAlertsFromActor } from '../components/alerts/OtherAlertsFromActor'
import { AlertsByCuid } from '../components/coverageMatrix/AlertsByCuid'

// Authentication imports
import { useMsal } from '@azure/msal-react'
import { tokenRequest } from '../utils/authConfig'
import { CallApi } from '../utils/api'

export const AttackMatrix = () => {

    const { instance, accounts } = useMsal()

    const [ cuidLoading, setCuidLoading ] = useState<boolean>(false)
    const [ aliasNotFound, setAliasNotFound ] = useState<boolean>(false)
    const [ targetSystem, setTargetSystem ] = useState<string>("Azure DevOps")

    const actorCuidRef = useRef<string>()

    const getActorCuid = (alias: string) => {
        setCuidLoading(true)
        setAliasNotFound(false)

        // Silently acquires an access token which is then attached to a request for the dotnet backend api
		instance.acquireTokenSilent({
			...tokenRequest,
			account: accounts[0]
		}).then((response) => {
			CallApi('/coveragematrix/getCuidFromAlias/' + alias, response.accessToken, 'Get')
				.then((response: any) => {
                    if (response != "[]") {
                        actorCuidRef.current =  JSON.parse(response)[0]['cuid']
                        setAliasNotFound(false)
                    }
                    else {
                        actorCuidRef.current = undefined
                        setAliasNotFound(true)
                    }
                    setCuidLoading(false)
				})
		})
    }
    
    return (
        <>
            <Flex alignItems={'center'} mb={"8px"}>
				<Text>Alias of user: </Text>
                <Input 
                    w={"260px"} 
                    mx={"8px"} 
                    placeholder='alias'
                    onChange={(e) => e?.target?.value.length > 3 ? getActorCuid(e?.target?.value) : null }
                />
                {
                    aliasNotFound ?
                    <Text>(alias not found)</Text>
                    :
                    null
                }
			</Flex>
            <Flex alignItems={'center'} mb={"8px"}>
				<Text>Show all security alerts in system: </Text>
				<Select w={"200px"} ml={"8px"} onChange={(e) => setTargetSystem(e?.target?.value)}>
					<option value='Azure DevOps'>Azure DevOps</option>
					<option value='ghecemu'>GHEC EMU</option>
					<option value='cloudbuild'>CloudBuild</option>
				</Select>
			</Flex>

            <MatrixGrid system={targetSystem} targetCuid={actorCuidRef.current} matrixType={"attack"}/>
            {
                actorCuidRef.current && !cuidLoading ?
                <Flex justifyContent={'center'} mt={"36px"}>
                    <Box w={"100%"}>
                        <OtherAlertsFromActor actorCuid={actorCuidRef.current}/>
                    </Box>
                </Flex>
                :
                null
            }
            
            
        </>
    )
}