import React, { useState, useEffect } from 'react'
import {
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
    Text,
    Spinner,
    Flex,
    Center,
    Stack
  } from '@chakra-ui/react'

interface Props {
    mappedData: any;
    loading: boolean;
}

export const AlertMappedDataTable = ({mappedData, loading} : Props) => {

    function LoadingComponent() {
        return (
            <Flex width={'100%'} height={'100%'} alignContent={'center'} justifyContent={'center'}>
                <Center>
                    <Stack my={"60px"}>
                        <Center mb={"20px"}>
                            <Spinner size='xl' />
                        </Center>
                        <Text>Loading Mapped Data...</Text>
                    </Stack>
                </Center>
            </Flex>
        )
    }

    if(!loading) {
        // Remove empty keys from mapped data
        let mappedDataKeys = Object.keys(mappedData)
        mappedDataKeys.forEach((key, i) => {
            if(mappedData[key].length === 0) {
                delete mappedData[key]
            }
        })

        // sort alphabetically
        let mappedDataKeysSorted = Object.keys(mappedData).sort()
        let mappedDataSorted:any = {}
        mappedDataKeysSorted.forEach((key, i) => {
            mappedDataSorted[key] = mappedData[key]
        })
        mappedData = mappedDataSorted        
    }

    // map data from json to table
    let dataRows:any = null
    if (!loading) {
        // Remove keys with same values as other keys
        let mappedDataKeys = Object.keys(mappedData)
        mappedDataKeys.forEach((key, i) => {
            mappedDataKeys.forEach((key2, i2) => {
                if(key !== key2 && mappedData[key] === mappedData[key2]) {
                    delete mappedData[key]
                }
            })
        })

        dataRows = Object.keys(mappedData).map((key, i) => {

            // Order arrays within keys
            if((mappedData as any)[key][0] == "[" && !mappedData[key].includes("SecMonFeedback"))
            {
                // Order array alphabetically
                let rowData:any = JSON.parse((mappedData)[key])
                rowData.sort()
                mappedData[key] = JSON.stringify(rowData)
            }

            return (
                <Tr key={i}>
                    <Td>{key}</Td>
                    <Td>{(mappedData as any)[key][0] == "[" && !mappedData[key].includes("SecMonFeedback") ?
                     JSON.parse((mappedData as any)[key]).map((arrayItem:any, itemIndex:number) => {
                        return (
                            <Text key={itemIndex}>
                                {
                                    arrayItem[0] == "[" || typeof arrayItem === "object" ?
                                    JSON.stringify(arrayItem)
                                    :
                                    arrayItem
                                }</Text>
                        )
                    }) : (mappedData as any)[key]}</Td>
                </Tr>
            )
        })
    }

    if(!loading) {
        return (
            <>
                <TableContainer>
                    <Table variant='simple' size='sm'>
                        <Tbody>
                            {dataRows}
                        </Tbody>
                    </Table>
                </TableContainer>            
            </>
        );
    }
    else {
        return (
            <>
                <LoadingComponent />
            </>
        );
    }
    
};