import React, { useState } from 'react'
// import { ChakraProvider } from '@chakra-ui/react'
import { AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { loginRequest, tokenRequest } from './utils/authConfig'
import { PageLayout } from './layouts/PageLayout'
import { ProfileData } from './components/ProfileData'

import { callMsGraph } from './utils/graph'
import Button from 'react-bootstrap/Button'
import { CallApi } from './utils/api'
import {
	BrowserRouter,
	Routes,
	Route,
} from 'react-router-dom'
import { InteractionStatus, InteractionType } from '@azure/msal-browser'

// CSS UI components
import { ChakraProvider, Center, Spinner, Flex, Box } from '@chakra-ui/react'

// Import Pages
import { Home } from './pages/Home'
import { KalypsoSchedule } from './pages/KalypsoSchedule'
import { Alerts } from './pages/Alerts'
import { FailedRuns } from './pages/FailedRuns'
import { CoverageMatrix } from './pages/CoverageMatrix'
import { AttackMatrix } from './pages/AttackMatrix'


/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
	const { instance, accounts } = useMsal()
	const [graphData, setGraphData] = useState<any>(null)

	function RequestProfileData() {
		// Silently acquires an access token which is then attached to a request for MS Graph data
		instance.acquireTokenSilent({
			...loginRequest,
			account: accounts[0]
		}).then((response) => {
			callMsGraph(response.accessToken).then(response => setGraphData(response))
		})
	}

	return (
		<>
			<h5 className="card-title">Welcome {accounts[0].name}</h5>
			{/* {graphData ? 
                <ProfileData graphData={graphData} />
                :
                <DefaultButton onClick={RequestProfileData}>Request Profile Information</DefaultButton>
            } */}
		</>
	)
}




const ErrorComponent = ({ error }: any) => {
	return <p>An Error Occurred: {error}</p>
}

function LoadingComponent() {
	return (
		<Flex width={'100vw'} height={'100vh'} alignContent={'center'} justifyContent={'center'}>
			<Center>
				<Spinner size='xl' />
			</Center>
		</Flex>
	)
}

export default function App() {


	return (
		<ChakraProvider>
			<MsalAuthenticationTemplate
				interactionType={InteractionType.Redirect}
				errorComponent={ErrorComponent}
				loadingComponent={LoadingComponent}
			>
				<PageLayout>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/schedules" element={<KalypsoSchedule />} />
						<Route path="/alerts" element={<Alerts />} />
						<Route path="/alerts/:instanceGuid" element={<Alerts />} />
						{/*<Route path="/failedRuns" element={<FailedRuns />}/>*/}
						<Route path="/coverage" element={<CoverageMatrix />} />
						<Route path="/attack" element={<AttackMatrix />} />
					</Routes>
				</PageLayout>
			</MsalAuthenticationTemplate>
		</ChakraProvider>

	)
}
