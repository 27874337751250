import React, { useState } from 'react'
import { AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'

import { loginRequest, tokenRequest } from '../utils/authConfig'
import { Box, Heading, Image, ListItem, UnorderedList } from '@chakra-ui/react'

const textLimits = {
    maxWidth: '1200px'
}

export const Home = () => {
    return (
        <>
            <Heading size='md' mb={2}>Farcaster</Heading>
            <p style={textLimits}> Farcaster is an internal full-stack application which serves to interact with Kalypso and Tethys. The goal of this application is to provide insights and allow users to perform actions on these security monitoring systems. </p>
            <br/>
            <Box boxSize='md' w='900px'>
                <Image src='/architecture.jpg' alt='Dan Abramov' />
            </Box>

            <p>The currently supported features of this application are:</p>
            <br/>
            <UnorderedList style={textLimits}>
                {/*<ListItem>
                    <Heading size='sm' mb={2}>
                        Viewing available run schedules for Kalypso analytics ("Schedules" tab)
                    </Heading>
                </ListItem>
                <p>
                    When developing new alerts for Kalypso, the developer needs to select the schedule in which the analyic will run.
                    Having many alerts running at the same time will cause increased strain on the system and may cause the alert to fail. 
                    This page allows the user to view the current schedules and find new ones to use for their alert.
                </p>
                <br/>
                <p>
                    This project was developed by Zhixing Jiang during her summer internship at Microsoft.
                </p>
            <br/>*/}

                <ListItem>
                    <Heading size='sm' mb={2}>
                        Viewing coverage matrix ("Coverage Matrix" tab)
                    </Heading>
                </ListItem>
                <p>
                    This page allows the user to all alerts developed by the 1ES Security Monitoring team by system and by surface & tactic.
                </p>
                <p>
                    Coming soon: allow user to view alerts onboarded by each SOC team and compare them to our published analytics.
                </p>
                <br/>

                <ListItem>
                    <Heading size='sm' mb={2}>
                        Viewing attack matrix ("Attack Matrix" tab)
                    </Heading>
                </ListItem>
                <p>
                    This page allows the user to view all triggered alerts by a specific actor and the tactics and surfaces they have targeted for each system.
                </p>
                <p>
                    Also provides a complete timeline of all alerts generated by the actor.
                </p>
                <br/>

                <ListItem>
                    <Heading size='sm' mb={2}>
                        Viewing insights on generated alerts ("Alerts" tab)
                    </Heading>
                </ListItem>
                <p>
                    This page allows the user to view the alerts that have been generated by Kalypso with additional data enrichments and parsing.
                </p>
                <p>
                    Data enrichments provided:
                </p>
                <UnorderedList>
                    <ListItem> Actor information </ListItem>
                    <ListItem> Employment status </ListItem>
                    <ListItem> Activity vs Actor location </ListItem>
                    <ListItem> Form to provide feedback on analytic </ListItem>
                    <ListItem> Other alerts generated by same actor in the past </ListItem>
                </UnorderedList>
                <br/>
                <p>
                    To get information on a specifc alert, simply append the instance id of the alert to the url as follows:
                </p>
                <p>
                    https://app-url/alerts/instance-id
                </p>
                <br/>
                {/*<p>
                    This project was developed by Duarte Dias as a way to easily visualize data from generated alerts.
                </p>*/}

            </UnorderedList>
            
        </>
    )
}