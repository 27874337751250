import React, { useState, useEffect } from 'react'

import {
    Heading,
    Avatar,
    Box,
    Center,
    Text,
    Stack,
    Button,
    Link,
    Badge,
    useColorModeValue,
    Flex,
    Image
  } from '@chakra-ui/react';

interface Props {
  employmentStatus: boolean;
}

export const EmploymentStatus = ( {employmentStatus} : Props ) => {

    return (
        <Center>
          <Box
            maxW={'320px'}
            w={'320px'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}>
          <Stack direction='row'>
            <Text>Employment status:</Text>
            <Badge colorScheme={employmentStatus ? 'green' : 'red'}>{employmentStatus ? 'Employed' : 'Terminated'}</Badge>
          </Stack>
          </Box>
        </Center>
      );
};