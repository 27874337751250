import React, { useEffect, useState } from 'react'

import { MatrixGridItem } from './MatrixGridItem'

import { Alert, AlertIcon, AlertTitle, Center, Flex, Grid, GridItem, Heading, HStack, Text, Tooltip} from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'

// Authentication imports
import { useMsal } from '@azure/msal-react'
import { tokenRequest } from '../../utils/authConfig'
import { CallApi } from '../../utils/api'

interface Props {
    system: string;
	matrixType: "coverage" | "attack";
	targetCuid?: string;
	targetTeam?: string;
}

const coverageMatrixes = {
	"Azure DevOps": {
		"surfaces": ["Permissions", "CI/CD", "Code", "Artifacts", "Persistence", "Work Items", "Administration"],
		"surfaceDescriptions": [
			"Accounts, roles, elevated permissions",
			"Continuous integration and deployment",
			"Source code",
			"Binaries, packages, or other assets produced by a build process",
			"Maintaining access or their foothold",
			"Azure DevOps work items",
			"Elevated, unrestricted permission"
		],
		"tactics": ["Service Exploit", "Recon", "Impact/Insertion", "Exfiltration", "Social"],
		"tacticDescriptions": [
			"Adversary is taking advantage of programming error and software vulnerabilities within a service or system",
			"Adversary is trying to gather information they can use to plan future operations",
			"Adversary is trying to manipulate, interrupt, or destroy your systems and data",
			"Adversary is trying to extricate data",
			"Adversary is trying to impersonate specific targets through social engineering and phishing"
		],
		"other": ["Fusion", "Threat Intel", "AI/ML"],
		"otherDescriptions": [
			"Combination of multiple analytics triggered for the same adversary",
			"Evidence-based information about cyber attacks",
			"Artificial intelligence and machine learning"
		]
	},
}

export const MatrixGrid = ({system, matrixType, targetCuid, targetTeam} : Props) => {

	const { instance, accounts } = useMsal()
	const [ alerts, setAlerts ] = useState<any[]>([])
	const [ loading, setLoading ] = useState<boolean>(false)

	switch (matrixType) {
		case "coverage":
			useEffect(() => {
				setLoading(true)
				instance.acquireTokenSilent({
					...tokenRequest,
					account: accounts[0]
				}).then((response) => {
					if(targetTeam)
					{
						if(targetTeam == 'all')
						{
							CallApi('/coveragematrix/getAdoCoverageMatrix', response.accessToken, 'Get')
							.then((response: any) => {
								setAlerts(JSON.parse(response))
								
							})
						}
						else
						{
							// Encode the team name to avoid issues with special characters
							targetTeam = encodeURIComponent(targetTeam)
							
							CallApi('/coveragematrix/getSocAnalytics/' + targetTeam, response.accessToken, 'Get')
							.then((response: any) => {
								setAlerts(JSON.parse(response))
							})
						}
						setLoading(false)
					}
				})
			}, [targetTeam]) // Pass empty array to avoid re-rendering on every change (only runs this code once)
			break;
		case "attack":
			useEffect(() => {
				if (targetCuid) {
					setLoading(true)
					// Silently acquires an access token which is then attached to a request for the dotnet backend api
					instance.acquireTokenSilent({
						...tokenRequest,
						account: accounts[0]
					}).then((response) => {
							CallApi('/coveragematrix/getAdoAttackMatrix/' + targetCuid, response.accessToken, 'Get')
							.then((response: any) => {
								setAlerts(JSON.parse(response))
								setLoading(false)
							})
					})
				}
				else {
					setAlerts([])
				}
			}, [targetCuid])
			break;
	}

	return (
		<>
			{
				!(system in coverageMatrixes)
				?
				<Center>
					<span>Matrix has not been implemented for system: {system}</span>
				</Center>
				:
				<Flex direction={"column"} alignItems={'center'}>
					<Heading mb={8} ml={290}>Tactic</Heading>
					<Grid templateColumns={'repeat(' + String(coverageMatrixes[system].tactics.length)  + ', 2fr)'} gap={3} ml={290} mb={4}>
						{
							coverageMatrixes[system].tactics.map((tactic, indexTactic) => {
								const description = coverageMatrixes[system].tacticDescriptions[indexTactic];

									return (
										<GridItem key={indexTactic}>
											<Center w={'25vh'}>
												<HStack>
													<Text>{tactic}</Text>
													<Tooltip label={description} fontSize='md'>
														<InfoIcon />
													</Tooltip>
												</HStack>
											</Center>
										</GridItem>
										
									)
							})
						}
					</Grid>
					<Flex alignItems={'center'}>
						<Heading mr={8}>Surface</Heading>
						<Grid templateColumns={'repeat(1, 2fr)'} gap={3} mx={4}>
							{
								coverageMatrixes[system].surfaces.map((surface, indexSurface) => {
									const description = coverageMatrixes[system].surfaceDescriptions[indexSurface];
										return (
											<GridItem h={'53'} key={indexSurface}>
												<Center h={'100%'}>
													<HStack>
														<Text>{surface}</Text>
														<Tooltip label={description} fontSize='md'>
															<InfoIcon />
														</Tooltip>
													</HStack>
												</Center>
											</GridItem>
											
										)
								})
							}
						</Grid>
						<Grid templateColumns={'repeat(' + String(coverageMatrixes[system].tactics.length)  + ', 2fr)'} gap={3}>
						{
							coverageMatrixes[system].surfaces.map((surface, indexSurface) => {
								return coverageMatrixes[system].tactics.map((tactic, indexTactic) => {
									return (
										alerts.length == 0 || loading ?
										<GridItem w='25vh' h='53'>
											<Center h={'100%'}>
												{
													matrixType == "coverage" ?
													<Alert status={'info'}>
														<AlertIcon />
														<AlertTitle>Loading...</AlertTitle>
													</Alert>
													:
													<>
														{
															loading ?
															<Alert status={'info'}>
																<AlertIcon />
																<AlertTitle>Loading...</AlertTitle>
															</Alert>
															:
															<Alert colorScheme={"gray"}>
																<AlertIcon />
																<AlertTitle>No info</AlertTitle>
															</Alert>
														}
													</>
												}
											</Center>
										</GridItem>
										:
										<>
											{
												matrixType == "coverage" ?
												<MatrixGridItem alerts={alerts.filter(x => x.Surface.toLowerCase() == surface.toLowerCase() && x.Tactic == tactic.toLowerCase().replace('/', ' '))} type={matrixType} key={indexSurface + indexTactic}/>
												:
												<MatrixGridItem alerts={alerts.filter(x => x.Surface.toLowerCase() == surface.toLowerCase() && x.Tactic == tactic.toLowerCase().replace('/', ' '))} type={matrixType} key={indexSurface + indexTactic}/>
											}
										</>
										
									)
								})
							})
						}
						</Grid>
					</Flex>
					<Grid templateColumns={'repeat(' + String(coverageMatrixes[system].tactics.length)  + ', 2fr)'} mt={100} gap={3} ml={290} mb={4}>
						{
							coverageMatrixes[system].other.map((otherHeader, indexOtherHeader) => {
								const description = coverageMatrixes[system].otherDescriptions[indexOtherHeader];
									return (
										<GridItem key={indexOtherHeader}>
											<Center w={'25vh'}>
												<HStack>
													<Text>{otherHeader}</Text>
													<Tooltip label={description} fontSize='md'>
														<InfoIcon />
													</Tooltip>
												</HStack>
											</Center>
											
										</GridItem>
										
									)
							})
						}
					</Grid>
					<Grid templateColumns={'repeat(' + String(coverageMatrixes[system].tactics.length)  + ', 2fr)'} gap={3} ml={290} mb={4}>
						{
							coverageMatrixes[system].other.map((other, indexOther) => {
									return (
										alerts.length == 0 || loading ?
										<GridItem w='25vh' h='53'>
											<Center h={'100%'}>
												{
													matrixType == "coverage" ?
													<Alert status={'info'}>
														<AlertIcon />
														<AlertTitle>Loading...</AlertTitle>
													</Alert>
													:
													<>
														{
															loading ?
															<Alert status={'info'}>
																<AlertIcon />
																<AlertTitle>Loading...</AlertTitle>
															</Alert>
															:
															<Alert colorScheme={"gray"}>
																<AlertIcon />
																<AlertTitle>No info</AlertTitle>
															</Alert>
														}
													</>
												}
											</Center>
										</GridItem>
										:
										<>
											{
												matrixType == "coverage" ?
												<MatrixGridItem alerts={alerts.filter(x => x.Tactic.toLowerCase() == other.toLowerCase().replace('/', ' '))} type={matrixType} key={indexOther}/>
												:
												<MatrixGridItem alerts={alerts.filter(x => x.Tactic.toLowerCase() == other.toLowerCase().replace('/', ' '))} type={matrixType} key={indexOther}/>
											}
										</>
										
									)
							})
						}
					</Grid>
				</Flex>
			}
		</>
	)
}