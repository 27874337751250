import React, { useState, useEffect } from 'react'

// Authentication imports
import { useMsal } from '@azure/msal-react'
import { tokenRequest } from '../../utils/authConfig'
import { CallApi } from '../../utils/api'

import {
    Heading,
    Box,
    Center,
    Link,
    useColorModeValue,
    TableContainer,
    Table,
    Tr,
    Td,
    Tbody
  } from '@chakra-ui/react';

interface Props {
  instanceGuid?: string;
  actorCuid?: string;
}

export const OtherAlertsFromActor = ({instanceGuid, actorCuid} : Props) => {

  const { instance, accounts } = useMsal()

  const [alertsList, setAlertsList] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
		// Silently acquires an access token which is then attached to a request for the dotnet backend api
		instance.acquireTokenSilent({
			...tokenRequest,
			account: accounts[0]
		}).then((response) => {
      if(instanceGuid != null) {
        CallApi('/alerts/getOtherAlertsByUser/' + instanceGuid, response.accessToken, 'Get')
        .then((response: any) => {
					setAlertsList(JSON.parse(response))
					setLoading(false)
				})
      }
      else if(actorCuid != null) {
        CallApi('/coveragematrix/getAdoAttackMatrix/' + actorCuid, response.accessToken, 'Get')
        .then((response: any) => {
          setAlertsList(JSON.parse(response))
          setLoading(false)
        })
      }			
				
		})
	}, []) // Pass empty array to avoid re-rendering on every change (only runs this code once)

  let dataRows = null
  if(!loading){
    dataRows = alertsList.sort((a:any, b:any) => (a.Timestamp > b.Timestamp) ? -1 : 1).map((alert: any) => {
      return (
        <Tr key={alert.InstanceGuid}>
          <Td><Link href={'/alerts/' + alert.InstanceGuid}>{alert.Timestamp.substring(0, 10) + ": " + alert.Title}</Link></Td>
        </Tr>
      )
    }
    )
  }



    return (
        <> 
          <Center>
            <Box
              maxW={'90%'}
              w={'full'}
              bg={useColorModeValue('white', 'gray.900')}
              boxShadow={'2xl'}
              rounded={'lg'}
              p={6}
              textAlign={'center'}>
              <Heading size='md' textAlign={'left'} pl={3} pb={4}>Alerts generated by this actor</Heading>
              <TableContainer>
                  <Table variant='simple' size='sm'>
                      <Tbody>
                        { loading ? <Tr><Td>Loading...</Td></Tr> : dataRows }
                      </Tbody>
                  </Table>
              </TableContainer>
            </Box>
          </Center>
        </>
      );
};