import React from 'react';
import {
    Box,
    Center,
    Text,
    Stack,
    Badge,
    useColorModeValue,
    Spinner
  } from '@chakra-ui/react';

interface Props {
  locationData: any;
  loading: boolean;
}

export const IpLocation = ({locationData, loading} : Props) => {

  if(loading) {

    return (
      <Center>
        <Box
          maxW={'320px'}
          w={'320px'}
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'lg'}
          p={6}
          textAlign={'center'}>
          <Stack gap={4} w={'100%'}>
            <Center>
              <Spinner m={"30px"} size='xl' />
            </Center>
          </Stack>
        </Box>
      </Center>
      );

  } else {

    return (
      <Center>
        <Box
          maxW={'320px'}
          w={'320px'}
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'lg'}
          p={6}
          textAlign={'center'}>
          <Stack gap={4} w={'100%'}>
            <Stack direction='row'>
              <Text>(Not yet implemented)</Text>
            </Stack>
            <Stack direction='row'>
              <Text>Location status:</Text>
              <Badge colorScheme={locationData.OfficeLocation == locationData.ActivityLocation ? "green" : "red"}>{locationData.OfficeLocation == locationData.ActivityLocation ? "Match" : "No Match"}</Badge>
            </Stack>
            <Stack direction='row'>
              <Text>Office location:</Text>
              <Text>{locationData.OfficeLocation}</Text>
            </Stack>
            <Stack direction='row'>
              <Text>Activity location:</Text>
              <Text>{locationData.ActivityLocation}</Text>
            </Stack>
          </Stack>
        </Box>
      </Center>
      );
  }
};